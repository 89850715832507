// extracted by mini-css-extract-plugin
export var column = "BlogPostNew__column__I41rT";
export var container = "BlogPostNew__container__K9ow0";
export var flex = "BlogPostNew__flex__ojyOS";
export var flexColumn = "BlogPostNew__flexColumn__IGfdR";
export var gap1 = "BlogPostNew__gap1__ucemG";
export var gap2 = "BlogPostNew__gap2__I7nhI";
export var gap3 = "BlogPostNew__gap3__gseYw";
export var gap4 = "BlogPostNew__gap4__L6PK9";
export var gap5 = "BlogPostNew__gap5__UiZBR";
export var pageTitle = "BlogPostNew__pageTitle__pg01P";
export var row = "BlogPostNew__row__rXxZb";